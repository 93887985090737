import React,{useState} from "react";
import './EventSpecific.css'
import ChatList from "../../components/ChatList/ChatList";
import ChatWindow from "../../components/ChatWindow/ChatWindow";

export default function EventSpecific(){
    const [selected, setSelected] = useState(null)
    return(
        <div className="es-container">
            <h1>Event Name</h1>
            <div className="flex">
                <ChatList setSelected={setSelected}/>
                <ChatWindow setSelected ={setSelected} selected={selected}/>
            </div>
        </div>
    )
}