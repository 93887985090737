import { faGrip, faLocationDot, faPhotoFilm} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import "./CardDetails.css"
import PhotoVideo from "../../../../modals/PhotoVideo/PhotoVideo";
import Preview from "./Preview/Preview";

export default function CardDetails(){
    const[imgURL, setImgURL] = useState('')
    const fileUploadRef  = useRef()

    const imageUpload =  (e) =>{
        e.preventDefault()
        fileUploadRef.current.click()
    } 

    const uploadImage =  () => {
        const uploadedFile  = fileUploadRef.current.files[0]
        const cachedURL = URL.createObjectURL(uploadedFile)
        setImgURL(cachedURL)
    }

    const[desc,setDesc]  = useState("")
    const[prev,setPrev] = useState(false)
    const[pvExpand,setPvExpand] = useState(false)
    
    return(
        <div className="dcd-main">
            {!prev ?  
            <form action="">
                <textarea 
                    type="text" 
                    className="desc" 
                    placeholder="Describe Your Event" 
                    onChange={(e)=> setDesc(e.target.value)}
                    required
                />
                <ul>
                    <li className="main-list">
                        <div className="list-flex" onClick={()=>  setPvExpand(true)} onMouseLeave={()=> setPvExpand(false)}>
                            <FontAwesomeIcon className="icon" icon={faPhotoFilm}/>
                            <label htmlFor="">Photo/Video</label>
                            {pvExpand && 
                                <PhotoVideo
                                    setImgURL={setImgURL}
                                    uploadImage={uploadImage}
                                    imageUpload={imageUpload}
                                    fileUploadRef={fileUploadRef}
                                />}
                        </div>
                    </li>
                    <li className="main-list">
                        <div className="list-flex">
                            <li><FontAwesomeIcon className="icon" icon={faGrip}/></li>
                            <li>
                                <select name="" id="">
                                    <option  disabled selected value>Select a Category</option>
                                    <option>Catering</option>
                                    <option>Transportation</option>
                                    <option>Entertainment </option>
                                    <option>Stage Decor</option>
                                    <option>Convention centre</option>
                                    <option>Bridal and Groom wear</option>
                                    <option>Media </option>
                                    <option>Security</option>
                                </select>
                            </li>
                        </div>
                    </li>
                    <li className="main-list">
                        <div className="list-flex">
                            <FontAwesomeIcon className="icon" icon={faLocationDot}/>
                            <button className="loc-btn">Add Location</button>
                        </div>
                    </li>
                </ul>
                <div className="btns">
                    <button className="cancel">Cancel</button>
                    <button type="submit" onClick={()=> setPrev(!prev)} className="p-btn">Preview</button>
                </div> 
            </form>
            :
            <Preview
                imgURL={imgURL}
                desc={desc}
            />
    }
        </div>
    )
}