import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Slider from "../../components/CategorySlider/Slider";
import Cards from "../../components/Cards/Cards";
import './FrontPage.css'
import FeedDetails from './Feeds.json';
import EventExpansion from "../../modals/EventExpansion/EventExpansion";
import AccountActions from "../../modals/AccountActions/AccountActions";

export default function FrontPage(){

    // function and state for event expansion
    const [selectedEvent, setSelectedEvent] = useState(null)
    const closeEventExpansion = () => {
        setSelectedEvent(null); 
    };

    return(
        <div className="f-container">
            <Navbar />
            <Slider/>
            <div className="grid">
                {FeedDetails.feed.map((item,index) => (
                    <Cards  
                        key={index}
                        mainpic={item.images[0]}
                        propic={item.profilePic}
                        company_name={item.company_name}
                        description = {item.description}
                        onClick={() => setSelectedEvent(item)}
                        />
                    ))}
            </div>
            {selectedEvent 
                && 
                <EventExpansion 
                    {...selectedEvent}
                    onClose={closeEventExpansion} 
                />
            }   
        </div>
    )
}