import './App.css';
import EventSpecific from './pages/EventSpecific/EventSpecific';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import FrontPage from './pages/FrontPage/FrontPage';
import LoginPage from './pages/LoginPage/LoginPage';
import MyEvents from './pages/MyEvents/MyEvents';
import SignUpPage from './pages/SignupPage/SignUp';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Vendor/Dashboard/Dashboard';
import VendorLoginPage from './pages/Vendor/LoginPage/VendorLoginPage';
import VendorSignUpPage from './pages/Vendor/SignupPage/VendorSignUp';
import EventListing from './pages/EventListing/EventListing';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/MyEvents' element={<MyEvents/>}/>
          <Route path='/ForgotPassword' element={<ForgotPassword/>}/>
          <Route path='/' element={<FrontPage/>}/>
          <Route path='/Signup' element={<SignUpPage/>}/>
          <Route path='/Login' element={<LoginPage/>}/>
          <Route path='/Event' element={<EventSpecific/>}/>
          <Route path='/Dashboard' element={<Dashboard/>}/>
          <Route path='/Vendorlogin' element={<VendorLoginPage/>}/>
          <Route path='/Vendorsignup' element={<VendorSignUpPage/>}/>
          <Route path='/Eventlisting' element={<EventListing/>}/>
        </Routes>
      </BrowserRouter>

    </div>
  )
}

export default App;
