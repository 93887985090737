import React, { useState, useRef, useEffect } from "react";
import './Slider.css'
import categoryData from "./Category.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";


export default function Slider(){

    const [activeLink,setActiveLink] = useState(1)
    const [showLeftArrow, setShowLeftArrow] = useState(false); 
    const [showRightArrow, setShowRightArrow] = useState(false);

    const containerRef = useRef(null); 

    // function to scroll to left using left arrow
    const scrollLeft = () => { 
        if (containerRef.current) { 
            containerRef.current.scrollBy({ left: -100, behavior: 'smooth' }); 
        } 
    };

    // function to scroll to left using left arrow
    const scrollRight = () => { 
        if (containerRef.current) { 
            containerRef.current.scrollBy({ left: 100, behavior: 'smooth' }); 
        }
    }

    // function for arrow visibility
    const updateArrowsVisibility = () => { 
        if (containerRef.current) { 
            const { scrollLeft, scrollWidth, clientWidth } = containerRef.current; 
            setShowLeftArrow(scrollLeft > 0); 
            setShowRightArrow(scrollLeft + clientWidth < scrollWidth); 
        } 
    }; 

    useEffect(() => { 
        updateArrowsVisibility(); 
        if (containerRef.current) { 
            containerRef.current.addEventListener('scroll', updateArrowsVisibility); 
        } 
        return () => { 
            if (containerRef.current) { 
                containerRef.current.removeEventListener('scroll', updateArrowsVisibility); 
            } 
        }; 
    }, []);

    return(
        <div className="cSl-container">
            {showLeftArrow && ( 
                <div className="arrowL" onClick={scrollLeft}> 
                    <FontAwesomeIcon className="sl-icon" icon={faChevronLeft} /> 
                </div> 
            )}
            <ul className="categories" ref={containerRef}>
                {categoryData.map((item,index) => (
                    <li key={index}
                        className={activeLink === item.id ? "active" : ""}
                        onClick={() => setActiveLink(item.id)}
                    >
                        <a>{item.name}</a>
                    </li>
                ))}
            </ul>
            {showRightArrow && ( 
                <div className="arrowR" onClick={scrollRight}>
                    <FontAwesomeIcon className="sl-icon" icon={faChevronRight} /> 
                </div> 
            )}
        </div>
    )
}