import React, { useRef, useState } from "react";
import "./PhotoVideo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilm, faImage} from "@fortawesome/free-solid-svg-icons";


export default function PhotoVideo(props){ 
    return(
        <div className="pv-menu-main">
            <div className="file">
                <div className="icon">
                    <FontAwesomeIcon icon={faImage}/>
                    <label htmlFor="">Photo</label>
                </div>
                <input type="file" accept="image/*" ref={props.fileUploadRef} onChange={props.uploadImage}/>
            </div>
            <hr />
            <div className="file">
                <div className="icon">
                    <FontAwesomeIcon icon={faFilm}/>
                    <label htmlFor="">Video</label>
                </div>
                <input type="file" accept="video/*"/>
            </div>
        </div>
    )
}