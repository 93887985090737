import { faChevronUp, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import './Profile.css'
import FileInput from "../../../../input/fileInput/FileInput";

export default function Profile(props){

    const[accordion,setAccordion] =  useState(false)
    const[fbVerified,setFBverified] = useState(false)
    const[igVerified,setIGverified] = useState(true)
    const[lnVerified,setLNverified] = useState(true)

    return(
        <div className="dp-main">
            <form>
                <div className="top">
                    <div className="left">
                        <input type="text" placeholder="Company  Name" value={props.cname} onChange={(e)=>props.setCName(e.target.value)}/>
                        <textarea placeholder="Description"></textarea>
                    </div>
                    <div className="right">
                        <div className="file-input">
                            <FileInput/>    
                        </div>
                        <input type="text" placeholder="GST Number" />
                        <input type="text" placeholder="Address" />
                    </div>
                </div>
                <div className="btns">
                    <button type="reset" className="white-btn">Cancel</button>
                    <button type="submit" className="blue-btn">Submit</button>
                </div>
                <div className="bottom">
                    <div className={`add-details ${accordion ? "expand" : ""}`}>
                        <div className="accordion-header" onClick={() => setAccordion(!accordion)}>
                            <h3>Social Profiles</h3>
                            <FontAwesomeIcon icon={faChevronUp} className={`${accordion ? "up" : "down"}`}/>
                        </div>
                        {accordion &&
                            <div className="accordion">
                                <div className="links">
                                    <div className="link">
                                        <label htmlFor="">Facebook</label>                                   
                                        <FontAwesomeIcon icon={faCircleCheck} className={`icon ${fbVerified ? "active":""}`}/>
                                    </div>
                                    <div className="link">
                                        <label htmlFor="">Instagram</label>
                                        <FontAwesomeIcon icon={faCircleCheck} className={`icon ${igVerified ? "active":""}`}/>
                                    </div>
                                    <div className="link">
                                        <label htmlFor="">LinkedIn</label>
                                        <FontAwesomeIcon icon={faCircleCheck} className={`icon ${lnVerified ? "active":""}`}/>
                                    </div>
                                </div>
                                
                            </div>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}