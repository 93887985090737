import React from "react";
import './Preview.css'

export default function Preview(props){
    return(
        <div className="preview">
            <div className="card">
                <div className="top">
                    <img src="" alt="" />
                    <h2>Company Name</h2>
                </div>
                <div className="con">
                    <img className="mImg" src={props.imgURL} alt="" />
                    <p>{props.desc}</p>
                </div>
            </div>
            <div className="btn">
                <button className="btn-white">Cancel</button>
                <button className="btn-blue">Submit</button>
            </div>
        </div>
    )
}