import React from "react";
import './Cards.css'

export default function Cards(props){
    const shortSentence = (sentence) => {
        const words = sentence.split('')
        if(words.length > 50){
            return words.slice(0,50).join('') + '...';
        }
        return sentence;
    }
    return(
        <div className="card-container" onClick={props.onClick}>
            <div className="card-main">
                <div className="card-top">
                    <div className="top">
                        {props.propic &&
                            <img className="profilePic" src={props.propic} alt="" />
                        }
                        <p className="user-name">{props.company_name}</p>
                    </div>
                </div>
                <img className="main-pic" src={props.mainpic} alt="" />
            </div>
            <p className="desc">{shortSentence(props.description)}</p>
        </div>
    )
}