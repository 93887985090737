import React, { useState } from "react";
import './ChatList.css'
import ContactItem from "../ContactItem/ContactItem";
import Contact from '../../pages/EventSpecific/Contact.json'

export default function ChatList(props){

    const [activeLink, setActiveLink] = useState('ALL');

    return(
        <div className="list-container">
            <h1>Quotations</h1>
            <div className="links">
                <ul>
                    <li>
                        <a href="#" 
                            className={activeLink === 'ALL' ? 'active' : ''}
                            onClick={() => setActiveLink('ALL')}
                            >
                                ALL
                        </a>
                    </li>
                    <li>
                        <a href="#" 
                            className={activeLink === 'NEW' ? 'active' : ''}
                            onClick={() => setActiveLink('NEW')}
                            >
                                NEW
                        </a>
                    </li>
                    <li>
                        <a href="#" 
                            className={activeLink === 'ONHOLD' ? 'active' : ''}
                            onClick={() => setActiveLink('ONHOLD')}

                            >
                                ON HOLD
                        </a>
                    </li>
                    <li>
                        <a href="#" 
                            className={activeLink === 'REJECTED' ? 'active' : ''}
                            onClick={() => setActiveLink('REJECTED')}
                            >
                                REJECTED
                        </a>
                    </li>
                </ul>
            </div>
            {activeLink === 'ALL' ? 
                (Contact.map((item) => (
                    <ContactItem
                        key={item.id}
                        data={item}
                        onClick={() => props.setSelected(item.id)}
                    />
                ))
                ) 
                : 
            activeLink === 'NEW' ? 
                (Contact.filter((item) => item.status === 'new').map((item) => (
                    <ContactItem
                        key={item.id}
                        data={item}
                        onClick={() => props.setSelected(item.id)}
                    />
                ))
                ) 
                : 
            activeLink === 'ONHOLD' ?
                (Contact.filter((item) => item.status === 'onhold').map((item) => (
                    <ContactItem
                        key={item.id}
                        data={item}
                        onClick={() => props.setSelected(item.id)}
                    />
                ))
                )
                :
            activeLink === 'REJECTED' ?
                (Contact.filter((item) => item.status === 'rejected').map((item) => (
                    <ContactItem
                        key={item.id}
                        data={item}
                        onClick={() => props.setSelected(item.id)}
                    />
                ))
                )
                :
                (<div>No items available</div>)
            }
        </div>
    )
}