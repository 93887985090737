import React from "react";
import './FileInput.css'
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FileInput(props){
    return (
        <div className="fileInput">
            <label htmlFor="" className="main-label">File Upload</label>
            <div className="icon">
                <FontAwesomeIcon className="imgIcon" icon={faImage}/>
                <label htmlFor="">Upload An Image</label>
            </div>
            <input type="file" accept="image/*" />
        </div>
    )
}