import React from "react";
import './LoginInput.css'

export default function LoginInput(props){
    return(
        <div className="spclInput">
            <input 
                type="text" 
                value={props.value} 
                name={props.name}
                onChange={props.onChange}
                required
            />
            <label>{props.label}</label>
        </div>
    )
}