import React,{useState} from "react"
import "./UserCard.css"
import user from "../../images/user.png"
import AccountActions from "../../modals/AccountActions/AccountActions"

export default function UserCard(props) {
    
    const gmail = "abcdefghijklmnopqrstuvwxyz123@gmail.com"
    const[actions,setActions] = useState(false)

    return (
        <div 
            className="userCard-container" 
            onClick={() => setActions(true)} 
        >
            <div className="left">
                <img 
                    src="https://picsum.photos/150/150?random=2" 
                    alt="user"
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = user
                    }}
                />
            </div>
            <div className="right">
                <p className="name">Travis Scott</p>
                <p className="email">{gmail}</p>
            </div>
            {actions && <AccountActions setActions={setActions}/>}
       
        </div>
    )
}