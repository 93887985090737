import React from "react";
import "./Dropdown.css"
import Category from "../../components/CategorySlider/Category.json"

export default function Dropdown(){
    return(
        <div className="spclDropDown">
            <select name="" id="">
                <option disabled selected>Choose a category</option>
                {Category.map((item,index) => (
                    <option key={index}>{item.name}</option>
                ))}
            </select>   
        </div>
    )
}