import React from "react";
import "./EventListing.css";
import EventCard from "../../components/EventCard/EventCard";
import EventDetails from "./EventDetails";
import Navbar from "../../components/Navbar/Navbar";

export default function EventListing(){
    return(
        <div className="eventListing-main">
            <Navbar/>
            <div className="grid">
                {EventDetails.map((event,index) => (
                    <EventCard
                        key = {index}
                        name  = {event.name}
                        details =  {event.details}
                        categories = {event.categories}
                        date = {event.date}
                        location = {event.location}
                    />
                ))}
            </div>
        </div>
    )
}