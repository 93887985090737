import React, { useState } from "react";
import './Table.css'
import { CustomerService } from "./Events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";

export default function Table(props){

    const customerData = CustomerService.getData()

    // pagination
    const [currentPage,setCurrentPage] = useState(1)
    const itemsPerPage = 8
    const totalPages = Math.ceil(customerData.length / itemsPerPage)
    const startIndex = (currentPage - 1) * itemsPerPage
    const currentData = customerData.slice(startIndex, startIndex + itemsPerPage)
    const numbers = [...Array(totalPages + 1).keys()].slice(1)
    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    // Limit the number of page buttons shown
    const pagesToShow = 5; 

    // Generate an array of page numbers to display
    const generatePageNumbers = () => {
        let start = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
        let end = Math.min(start + pagesToShow - 1, totalPages);

        // Adjust the starting point if we are too close to the end
        if (end - start < pagesToShow - 1) {
        start = Math.max(end - pagesToShow + 1, 1);
        }

        let pageNumbers = [];
        for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
        }

        return pageNumbers;
    };

    // value for select filter
    const [filter,setFilter] = useState('');;
    return(
        <div className="table-container">
            <div className="top">
                <h1>All Events</h1>
                <div className="search">
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon"/>
                    <input type="search" placeholder="Search" className="table-search" />
                </div>
                <div className="right">
                    <div className="sort-by">
                        <p>
                            <span className="sort-label">Sort By:</span> 
                        </p>
                        <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                            <option value="Newest First">Newest First</option>
                            <option value="Oldest First">Oldest First</option>
                            <option value="Name (A to Z)">Name: A to Z</option>
                            <option value="Name (Z to A)">Name: Z to A</option>
                            <option value="Price (Low to High)">Price: Low to High</option>
                            <option value="Price (High to Low)">Price: High to Low</option>
                        </select>
                    </div>
                    <div className="add-btn">
                        <FontAwesomeIcon icon={faPlus} className="add-icon"/>
                        <button onClick={props.toggleCreateEvent}>Add a new event</button>
                    </div>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Event Name</th>
                        <th>Active Quote</th>
                        <th className="action-th">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((item)=>(
                    <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.quote}</td>
                        <td className="btn-td">
                            <button className="view-btn">View</button>
                            <button className="edit-btn">Edit</button>
                            <button className="delete-btn">Delete</button>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>

            <div className="pagination">
                <button
                    className="page-btn"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                >
                    {"<"}
                </button>

                {generatePageNumbers().map((n) => (
                    <button
                        className={`page-btn ${currentPage === n ? 'active' : '' }`} 
                        key={n}
                        onClick={() => handlePageChange(n)}
                    >
                        {n}
                    </button>
                ))}
                <button
                    className="page-btn"
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    {">"}
                </button>

            </div>
        </div>
    )
}