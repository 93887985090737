import React, { useState } from 'react'
import './MyEvents.css'
import Navbar from '../../components/Navbar/Navbar'
import Table from '../../components/EventsTable/Table'
import CreateEvent from '../../modals/CreateEvent/CreateEvent'

export default function MyEvents(){
    const[createEvent,setCreateEvent] = useState(false)

    function toggleCreateEvent(e){
        setCreateEvent(!createEvent)
        e.preventDefault()

    }
    return(
        <div className="event-main">
            <Navbar/>
            <div className="event-container">
                <div className="table">
                    <Table toggleCreateEvent = {toggleCreateEvent}/>
                </div>
            </div>
            {createEvent &&

                <CreateEvent toggleCreateEvent = {toggleCreateEvent}/>

            }
        </div>
    )
}