import React from "react";
import "./AccountActions.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";

export default function AccountActions(props){
    return(
        <div className="aa-container" onMouseLeave={() => props.setActions(false) }>
            <div className="row1">
                <FontAwesomeIcon icon={faUser} className="icon"/>
                <p>Profile</p>
            </div>
            <div className="row2">
                <FontAwesomeIcon icon={faArrowRightFromBracket} className="icon"/>
                <p>Logout</p>
            </div>
        </div>
    )
}