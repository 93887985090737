import React from "react";
import './ContactItem.css'

export default function ContactItem(props){
    return(
        <div className="contact-container" onClick={props.onClick}>
            <img src={props.data.img}/>
            <div className="right">
                <p className="user-name">{props.data.name}</p>
                <p className="amount">Rs.{props.data.quote}</p>
            </div>
        </div>
    )
}