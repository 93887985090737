import React from "react";
import './Feed.css'
import FeedDetails from "../../../FrontPage/Feeds.json"
import Cards from "../../../../components/Cards/Cards";

export default function Feed(){
    return(
        <div className="feed-main">
             <div className="grid">
                {FeedDetails.feed.map((item,index) => (
                    <Cards key={index}
                            mainpic={item.images[0]}
                            company_name={""}
                            description = {""}
                    />
                ))}
            </div>

        </div>
    )
}