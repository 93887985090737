import React from "react";
import './ChatWindow.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faDownload, faX } from "@fortawesome/free-solid-svg-icons";
import Contact from '../../pages/EventSpecific/Contact.json'
import nullChat from '../../images/null chat.svg'

export default function ChatWindow(props){

    const selectedContact = Contact[props.selected - 1]

    return(
        <div className="window-main">
            {props.selected  ? 
            <div className="window-container">
                <div className="top">
                    <div className="left">
                        <img src={selectedContact.img} alt="" className="profile"/>
                        <p className="user-name">{selectedContact.name}</p>
                    </div>
                    <FontAwesomeIcon icon={faX} className="close-icon" onClick={() => props.setSelected(0)}/>
                </div>
                <div className="area">
                    <div className="chat-area">
                        <div className="quotation">
                            <h3>Rs.{selectedContact.quote}</h3>
                            <div className="bottom">
                                <div className="see-more">
                                    <p>See More</p>
                                    <FontAwesomeIcon icon={faChevronDown} className="see-icon"/>
                                </div>
                                <div className="download">
                                    <p>Invoice</p>
                                    <FontAwesomeIcon icon={faDownload} className="download-icon"/>

                                </div>
                            </div>
                        </div>
                        <div className="btns">
                            <button className="hold-btn">Hold</button>
                            <button className="cancel-btn">Cancel</button>
                        </div>

                    </div>
                </div>
                <div className="message-area">
                    <form action="">
                        <input type="text" placeholder="Type your message here" />
                        <button type="submit">
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                height="24px" 
                                viewBox="0 -960 960 960" 
                                width="24px" 
                                fill="black"
                            >
                                <path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"/>
                            </svg>
                        </button>
                    </form>
                </div>
            </div>
            :
            <div className="null-container">
                <img src={nullChat} alt="" />
                <h1>Select a chat to view conversation</h1>
                
            </div>
        }
        </div>
    )
}