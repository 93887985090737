import React from "react"
import "./ForgotPassword.css"
import leftImg from "./img.png"
import LoginInput from "../../input/loginInput/LoginInput"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

export default function ForgotPassword(){
    return(
        <div className="fp-container">
            <div className="main">
                <div className="left">
                    <img src={leftImg} alt=""/>
                    <Link to='/Signup'><button>Create a new account</button></Link>
                </div>
                <div className="right">
                    <h1>Forgot your password?</h1>
                    <form action="">
                        <LoginInput label="Enter your email address"/>
                        <button type="submit" className="reset-btn">Submit</button>
                    </form>

                    <a href="" disabled>Re-send Link</a>
                    <div className="bottom-link">
                        <FontAwesomeIcon className="arrow-icon" icon={faArrowLeft}/>
                    <   Link to='/Login' href="">Back to Login</Link>
                    </div>
                </div>

            </div>
        </div>
    )
}